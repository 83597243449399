import React from "react";
import Categoria from "./Categoria";
import { SpinnerInfinity } from 'spinners-react';

class CategoriaGeneral extends React.Component {
    constructor() {
        super();
        this.params = new URLSearchParams(window.location.search);
        this.state = {
            data: [],
            colores: [],
            subcategorias: [],
            parents: [],
            dataReady: false
        }
    }

    componentDidMount = async () => {
        let categoria = window.location.pathname.split('/')[2];
        categoria = categoria.replaceAll('-', ' ');
        let subcategoria = window.location.pathname.split('/')[3];
        subcategoria = subcategoria.replaceAll('-', ' ');
        let color = '';
        if (this.params.get("color")) {
            color = this.params.get("color");
          }
        if (this.params.get("cat")) {
            subcategoria = this.params.get("cat");
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'filtrar',
                categoria: categoria,
                subcategoria: subcategoria,
                color: color
            })
          };
        const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
        const data = await response.json();
        this.setState({
            data: data.datos,
            colores: data.colores,
            subcategorias: data.subcategorias,
            parents: Object.values(data.parents),
            dataReady: true
        })
    }

    render() {
        return (
            <>
                <div className="container-fluid">
                    {
                        this.state.dataReady ?
                            <Categoria 
                                data={this.state.parents}
                                colores={this.state.colores} 
                                subcategorias={this.state.subcategorias}
                                />
                        :
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center mt-5 mb-5">
                                <div style={{ backgroundColor: '#53565C', width: 'fit-content', padding: '20px', borderRadius: '10px' }}>
                                    <SpinnerInfinity size={200} thickness={70} speed={150} color="rgba(206, 6, 5, 1)" secondaryColor="rgba(255, 255, 255, 1)"/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        );
    }
}
 
export default CategoriaGeneral;