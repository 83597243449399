import React from 'react';
import '../css/tarjeta.css';
import { Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import NotFoundImage from '../images/noImage.png';

class Tarjeta extends React.Component {
    
    constructor(){
        super();
        this.state = {
            url: '',
            colores : [],
            img: '',
            imgGeneral : '',
            precio: '',

            dataReady: false
        }
      }

    componentDidMount = async () => {
        this.cargarDatos();
        this.imgGeneral();
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.data.codigoGeneral !== this.props.data.codigoGeneral) {
            this.setState({
                dataReady: false,
            })
            this.cargarDatos();
            this.imgGeneral();
        }
    }

    cargarDatos = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'getColores',
                codigoGeneral: this.props.data.codigoGeneral
            })
          };
          const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
          const data = await response.json();
        this.setState({
            url: this.props.data.url,
            colores: data.colores,
            dataReady: true
        })
    }

    imgGeneral = async() => {
        if (this.props.data.proveedor === 'PO') {
            if (this.props.data.codigoGeneral !== '' && this.props.data.codigoGeneral !== null) {
                let imgGeneral = this.props.data.codigoGeneral.replaceAll(' ', '-') + '.jpg';
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'existe', ruta: imgGeneral })
                };
                const response = await fetch('https://www.argpromocionales.com/php/existeImagen.php', requestOptions);
                const data = await response.json();
                if (data) {
                    this.setState({
                        imgGeneral: window.location.origin + this.props.data.imgGeneral,
                        img: window.location.origin + this.props.data.imgGeneral
                     })
                }else{
                    this.setState({
                        imgGeneral: this.props.data.imgColor,
                        img: this.props.data.imgColor
                     })
                }
            }else{
                this.setState({
                    img: ''
                })
            }
        }else{
            this.setState({
                imgGeneral: this.props.data.imgGeneral,
                img: this.props.data.imgGeneral
            })
        }
    }

    changeImg = (img) => {
        if (this.state.img === img) {
            this.setState({
                img: this.state.imgGeneral
            })
        }else{
            this.setState({
                img: img
            })
        }
    }

    capitalizar = (color) => {
        const arr = color.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === '0.00') {
            return 'Consultar';
        }else{
            return '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    render() {
        return (
            <>
                {
                    (this.props.data.codigoGeneral !== '' && this.props.data.codigoGeneral !== null) &&
                <div className="col-md-6 px-0 px-sm-3 mb-3">
                    {
                        this.state.dataReady ? 
                            <div className="tarjeta text-center card">
                                <div className="row p-0">
                                    <div className="col">
                                        <h4 className='tituloTarjeta'>{this.props.data.nombre.toUpperCase()}</h4>
                                    </div>
                                </div>
                                <div className="row p-0">
                                    <div className="col-7 d-flex justify-content-center align-items-center colImgTarjeta">
                                        <a href={this.state.url} aria-label={"Boton del producto " + this.props.data.nombre.toUpperCase()}><img src={this.state.img} alt="" loading={this.props.index > 9 ? 'lazy' : 'eager'} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src=NotFoundImage; }} width="100%" height="100%" className='imgTarjeta'/></a>
                                    </div>
                                    <div className="col-5 ps-0">
                                        <OverlayTrigger
                                        trigger={['click']}
                                        placement={'top'}
                                        overlay={
                                            <Popover id={`popover-positioned-top`}>
                                                <Popover.Body>
                                                    <p>{this.props.data.proveedor}</p>                                                    
                                                </Popover.Body>
                                            </Popover>
                                        }
                                        >   
                                            <button style={{ border: 'none', backgroundColor: 'transparent', fontWeight: 'bold', fontSize: '20px', color: '#53565b', width: 'fit-content', cursor: 'default' }}>{this.props.data.codigoGeneral ? this.props.data.codigoGeneral : 'No Disponible'}</button>
                                        </OverlayTrigger>
                                        <hr />
                                        <div className="col d-flex justify-content-center align-items-center" style={{ height: '50%' }}>
                                            <div>
                                                {   
                                                    this.state.dataReady ? 
                                                        this.state.colores.map((e, index) =>
                                                            <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            key={index}
                                                            placement={'top'}
                                                            overlay={
                                                                <Popover id={`popover-positioned-top`}>
                                                                <Popover.Body>
                                                                    {this.capitalizar(e.color)}
                                                                </Popover.Body>
                                                                </Popover>
                                                            }
                                                            >
                                                            <button className={'btnColor mx-1 btnColor' + e.btnColor} aria-label={"Boton de color del producto " + e.btnColor} onClick={ () => this.changeImg(e.img)}></button>
                                                            </OverlayTrigger>
                                                        )
                                                    :
                                                            <h6>Cargando</h6>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a href={this.state.url} className="btnTarjetaVerMas">{('Ver disponibilidad').toUpperCase()}</a>                            
                            </div>
                        :
                            <div className="tarjeta text-center card">
                                <div className="row p-0">
                                    <div className="col">
                                        <h4 className='tituloTarjeta'>-</h4>
                                    </div>
                                </div>
                                <div className="row p-0">
                                    <div className="col-7 d-flex justify-content-center align-items-center colImgTarjeta">
                                        <img src={NotFoundImage} alt="" className='imgTarjeta'/>
                                    </div>
                                    <div className="col-5 ps-0">
                                        <h5 style={{ fontWeight: 'bold', color: '#53565b' }}>-</h5>
                                        <hr />
                                        <h6 style={{ display: 'inline', fontWeight: 'bold', color: '#53565b' }}>-</h6>
                                        <hr />
                                        <div className="col d-flex justify-content-center align-items-center" style={{ height: '50%' }}>
                                            <div>
                                                {   
                                                    this.state.dataReady ? 
                                                        this.state.colores.map((e, index) =>
                                                            <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            key={index}
                                                            placement={'top'}
                                                            overlay={
                                                                <Popover id={`popover-positioned-top`}>
                                                                <Popover.Body>
                                                                    {this.capitalizar(e.color)}
                                                                </Popover.Body>
                                                                </Popover>
                                                            }
                                                            >
                                                            <button className={'btnColor mx-1 btnColor' + e.btnColor} aria-label={"Boton de color del producto " + e.btnColor} onClick={ () => this.changeImg(e.img)}></button>
                                                            </OverlayTrigger>
                                                        )
                                                    :
                                                            <h6>-</h6>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button type='button' className="btn btn-sm btn-danger btnTarjetaVerMas w-100">{('Ver disponibilidad').toUpperCase()}</button>                            
                            </div>
                    }
                </div>
                }
            </>
        );
    }
}

export default Tarjeta;