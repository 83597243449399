import React from 'react';

class ProductoLista extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            url: '',
            imgGeneral : ''
        }
    }

    componentDidMount = () => {
        this.cargarUrl();
        this.cargarImgGeneral();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.codigoGeneral !== this.props.data.codigoGeneral) {
            this.cargarUrl();
            this.cargarImgGeneral();
            this.cargarImgGeneral();
        }
    }

    cargarUrl = () => {
        if (this.props.data.codigoGeneral !== '' && this.props.data.nombre !== '') {
            this.setState({
                url: this.props.data.url
            });
        }
    }

    cargarImgGeneral = async () => {
        if (this.props.data.proveedor === 'PO') {
            if (this.props.data.codigoGeneral !== '' && this.props.data.codigoGeneral !== null) {
                let imgGeneral = this.props.data.codigoGeneral.replaceAll(' ', '-') + '.jpg';
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ operacion: 'existe', ruta: imgGeneral })
                };
                const response = await fetch('https://www.argpromocionales.com/php/existeImagen.php', requestOptions);
                const data = await response.json();
                if (data) {
                    this.setState({
                        imgGeneral: window.location.origin + this.props.data.imgGeneral
                     })
                }else{
                    this.setState({
                        imgGeneral: this.props.data.imgColor
                     })
                }
            }
        }else{
            this.setState({
                imgGeneral: this.props.data.imgGeneral
            })
        }
    }

    capitalizar = (color) => {
        const arr = color.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    render() { 
        return ( 
            <li className='list-group-item'>
                <div className="row p-0">
                    <div className="col-2 p-0">
                        <a href={this.state.url}><img src={this.state.imgGeneral} alt="" className='imgLista'/></a>
                    </div>
                    <div className="col-10">
                        <div className="row p-0">
                            <div className="col-12">
                                <div style={{ height: '25px', overflow: 'hidden' }}>
                                    <a href={this.state.url} className='linkLista'>{this.capitalizar(this.props.data.nombre)}</a>
                                </div>
                            </div>
                            <div className="col-12">
                                {this.props.data.codigoGeneral}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
         );
    }
}
 
export default ProductoLista;