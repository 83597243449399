import React from 'react';
import '../css/whatsappBtn.css';

class WhatsappBtn extends React.Component {
    state = {  } 
    render() { 
        return (
            <>
                <div className="btnFlotantes">
                    <a href="https://wa.me/527714733271" className="float d-flex align-items-center justify-content-center" target="_blank" rel="noopener noreferrer" aria-label='Iniciar conversacion en whatsapp'>
                        <i className="bi bi-whatsapp my-float"></i>
                    </a>
                    <a href="https://www.instagram.com/arg.promocionales" className="float2 d-flex align-items-center justify-content-center" target="_blank" rel="noopener noreferrer" aria-label='Visitar instagram de argpromocionales'>
                        <i className="bi bi-instagram my-float"></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100078723923333" className="float3 d-flex align-items-center justify-content-center" target="_blank" rel="noopener noreferrer" aria-label='Visitar facebook de argpromocionales'>
                        <i className="bi bi-facebook my-float"></i>
                    </a>
                </div>
            </>
        );
    }
}
 
export default WhatsappBtn;