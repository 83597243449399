import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Contacto from '../Paginas/Contacto';
import Inicio from '../Paginas/Inicio';
import Impresion from '../Paginas/Impresion';
import Somos from '../Paginas/Somos';
import CategoriaGeneral from './Categorias/CategoriaGeneral';
import ProductoPO from './Productos/ProductoPO';
import ProductoDV from './Productos/ProductoDV';
import ProductoCO from './Productos/ProductosCO';
import Busqueda from './Busqueda';
import NotFoundRoute from './NotFoundRoute';
import Temporada from './Temporada';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-233550602-1";

ReactGA.initialize(TRACKING_ID);

class Routes extends React.Component {

  constructor(){
    super();
    this.state = {
        productos: [],
        temporada: [] 
    }
  }

  componentDidMount = async () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    /*const requestOptionsTemporada = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    };
    const responseTemporada = await fetch('https://www.argpromocionales.com/php/productosTemporada.php', requestOptionsTemporada);
    const dataTemporada = await responseTemporada.json();*/
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
          operacion: 'getParentsRoutes'
      })
    };
    const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
    const data = await response.json();
    this.setState({
      productos: data.productos,
      //temporada: dataTemporada
    });
}

  render() {
    return (
      <Switch>
          <Route exact path="/" component={Inicio}/> 
          <Route exact path="/contacto" component={Contacto}/> 
          <Route exact path="/impresion"component={Impresion}/>
          {
            this.state.temporada.titulo &&
              <Route exact path={"/" + this.state.temporada.titulo.replaceAll(' ','-').toLowerCase()} render={(props) => <Temporada {...props} titulo={this.state.temporada.titulo}/>}/>
          }
          <Route path="/categorias/"component={CategoriaGeneral}/>
          {this.state.productos[0] && this.state.productos.map((e, index) => {
            if (this.state.productos[index].url !=='') {
              if (this.state.productos[index].proveedor === 'PO') {
                return <Route key={index} exact path={e.url} render={(props) => <ProductoPO {...props} codigoEspecifico={e.codigoEspecifico} codigoGeneral={e.codigoGeneral}/>}/>
              }
              if (this.state.productos[index].proveedor === 'DV') {
                return <Route key={index} exact path={e.url} render={(props) => <ProductoDV {...props} codigoEspecifico={e.codigoEspecifico} codigoGeneral={e.codigoGeneral} subcategoria={e.subcategoria}/>}/>
              }
              if (this.state.productos[index].proveedor === 'CO') {
                return <Route key={index} exact path={e.url} render={(props) => <ProductoCO {...props} codigoEspecifico={e.codigoEspecifico} codigoGeneral={e.codigoGeneral} subcategoria={e.subcategoria}/>}/>
              }
            }
            return ''
          })}
          <Route path="/busqueda" component={Busqueda}/>
          <Route exact path="/somos"component={Somos}/>
          {
            this.state.productos[0] && 
              <Route exact path="*" component={NotFoundRoute}/>
          }
          </Switch>
    );
  }

};

export default Routes;