import React from "react";
import "../Paginas/impresion.css";
import imp1 from "../images/imp1.jpg"
import imp2 from "../images/imp2.jpg"
import imp3 from "../images/imp3.jpg"
import imp4 from "../images/imp4.jpg"
import impresion1 from "../images/impresion1.jpg";
import impresion2 from "../images/impresion2.jpg";
import impresion3 from "../images/impresion3.jpg";
import impresion4 from "../images/impresion4.jpg";


class Impresion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    document.title = "ARG - Impresión";
  };

  render() {
    return (
      <>
        {/* Carrusel de impreiones */}
                    <div id="carouselExampleDark" className="carousel carousel-dark slide">
                    <div className="carousel-indicators py-5">
                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                      <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="3" aria-label="Slide 4"></button>
                    </div>
                    <div className="carousel-inner text-align-center">
                      <div className="carousel-item active" data-bs-interval="10000">
                        <img src={imp1} className="d-block w-100 d-img" alt="..."/>
                        <div className="carousel-caption d-none d-item d-md-block">
                          <h5>Grabado de láser</h5>
                        </div>
                      </div>
                      <div className="carousel-item d-item" data-bs-interval="2000">
                        
                        <img src={imp2} className="d-block w-100 d-img" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                          <h5>Serigrafía</h5>
                        </div>
                      </div>
                      <div className="carousel-item d-item">
                        <img src={imp3} className="d-block w-100 d-img" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                          <h5>Tampografía</h5>
                        </div>
                    </div>
                    <div className="carousel-item d-item">
                        <img src={imp4} className="d-block w-100 d-img" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                          <h5>Gota de resina</h5>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div> 
                  </div>        

        {/* /////////////////////////////// */}
        <div>
          <div className="py-3 cont-tit">
              <div className="container">
                  <h3>Impresiones</h3>
              </div>
          </div>
          <div className="py-3 bg-light border-botton">
              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <h4>Grabado láser</h4>
                      <div className="dividir">
                        <p>
                         El grabado láser es un proceso de fabricación por sustracción que utiliza un haz láser para modificar la superficie de un objeto.

                           Es un método rápido, ya que el material se elimina con cada pulsación del láser. La profundidad de las marcas se controla variando el número de pasadas del láser sobre la superficie.
                        </p>
                      </div>
                  </div>
                  <div className="col-md-3 py-4">
                          <img src={impresion1} className="w-100 shadow" alt=""/>
                      </div>
                  <div className="col-md-8">
                    <h4>Serigrafía</h4>
                      <div className="dividir">
                        <p>
                        La serigrafía es una técnica de impresión que consiste en preparar una pantalla o bastidor con una tela tensada en la que habremos plasmado un diseño mediante la aplicación de un barniz que bloquea la zona de la tela sin imagen.
                        </p>
                      </div>
                  </div>
                  <div className="col-md-3 py-4">
                          <img src={impresion2} className="w-100 shadow" alt=""/>
                      </div>
                  <div className="col-md-8">
                    <h4>Tampografía</h4>
                      <div className="dividir">
                        <p>
                        La tampografía es un sistema de impresión basado en la transferencia de tinta desde una superficie plana, el cliché, a otra superficie que puede ser plana, cóncava, convexa o la combinación de todas ellas gracias a un tampón, fabricado con un elastómero sintético, que se adapta perfectamente a formas irregulares.                        </p>
                      </div>
                  </div>
                  <div className="col-md-3 py-4">
                          <img src={impresion3} className="w-100 shadow" alt="" />
                      </div>
                  <div className="col-md-8">
                    <h4>Gota de resina</h4>
                      <div className="dividir">
                        <p>
                        La técnica de gota de resina es una combinación de una etiqueta de vinil impresa con adhesivo que puede ser impresa a todo color, al que se aplica posteriormente una gota de resina de poliuretano totalmente transparente para producir un efecto lupa o 3D con el fin de resaltar los autoadhesivos o etiquetas.
                        </p>                      
                      </div>
                  </div>
                  <div className="col-md-3 py-4">
                          <img src={impresion4} className="w-100 shadow" alt=""/>
                      </div>
                </div>
              </div>
          </div>
        </div>
      </>
    );
  }
}

export default Impresion;
