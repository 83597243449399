import React from 'react';
import Categoria from './Categorias/Categoria';
import { SpinnerInfinity } from 'spinners-react';

/* eslint no-eval: 0 */

class Temporada extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            colores: [],
            categoria: '',
            subcategorias: [],
            parents: [],
            colorFiltro : '',
            subcategoriaFiltro : '',
            minPrecioFiltro: '',
            maxPrecioFiltro: '',
            disponiblesFiltro: '',
            dataReady: false
        }
    }

    componentDidMount = async () => {
        document.title = 'ARG - ' + this.props.titulo;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const response = await fetch('https://www.argpromocionales.com/php/Catalogo.php', requestOptions);
        const data = await response.json();
        let datos = Object.values(data);
        const requestOptionsTemporada = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' }
        };
        const responseTemporada = await fetch('https://www.argpromocionales.com/php/productosTemporada.php', requestOptionsTemporada);
        const dataTemporada = await responseTemporada.json();
        let filtrados = '';
        dataTemporada.productos.forEach((element, index) => {
            if (index === (dataTemporada.productos.length - 1)) {
                filtrados += "producto.codigoGeneral === '" + element.parent_code + "'";   
            }else{
                filtrados += "producto.codigoGeneral === '" + element.parent_code + "' || ";
            }
        });
        let datosFiltrados = datos.filter(producto => {
            return eval(filtrados)
        });
        let colores = [];
        datosFiltrados.forEach((e, index) => {
            colores[index]= e.color;
        });
        let uniqueColors = [...new Set(colores)];
        let subcategorias = [];
        datosFiltrados.forEach((e, index) => {
            subcategorias[index] = e.subcategoria;
        });
        let uniqueSubcategorias = [...new Set(subcategorias)];
        this.setState({
            data: datosFiltrados,
            colores: uniqueColors,
            subcategorias: uniqueSubcategorias
        });
        this.obtenerParents(datosFiltrados);
    }

    obtenerParents = async (datos) => {
        let parents = [];
        datos.forEach((e) => {
            parents[e.codigoGeneral] = {
                proveedor: e.proveedor,
                codigoGeneral: e.codigoGeneral,
                nombre : e.nombre,
                imgGeneral: e.imgGeneral,
                imgColor: e.imgColor,
                precio: e.precio,
                url: e.url
            }
        });
        this.setState({
            parents: Object.values(parents),
            dataReady: true
        })
    }

    filtrar = () => {
        let datos = this.state.data;
        if(this.state.colorFiltro !== ''){
            datos = datos.filter(producto => producto.color === this.state.colorFiltro);
        }
        if(this.state.subcategoriaFiltro !== ''){
            datos = datos.filter(producto => producto.subcategoria === this.state.subcategoriaFiltro);
        }
        if(this.state.minPrecioFiltro !== ''){
            datos = datos.filter(producto => (this.obtenerPrecio(Number(producto.precio), producto.proveedor) >= Number(this.state.minPrecioFiltro)))
        }
        if(this.state.maxPrecioFiltro !== ''){
            datos = datos.filter(producto => (this.obtenerPrecio(Number(producto.precio), producto.proveedor) <= Number(this.state.maxPrecioFiltro)))
        }
        if(this.state.disponiblesFiltro !== ''){
            datos = datos.filter(producto => (producto.existencias === 'Si'))
        }
        this.obtenerParents(datos);
    }

    obtenerPrecio = (precio, proveedor) => {
        let precioC = 0;
        if (proveedor === 'PO') {
            precioC = (precio/(1-0.32)).toFixed(2);
        }
        if (proveedor === 'DV') {
            precio = precio * 1.05;
            precioC = (precio/(1-0.45)).toFixed(2);
        }
        if (proveedor === 'CO') {
            precio = precio * 0.96;
            precioC = (precio/(1-0.35)).toFixed(2);
        }
        return precioC
    }

    filtrarColor = async (color) => {
        await  this.setState({
             colorFiltro: color
         })
         this.filtrar();
     }
 
     quitarFiltroColores = async () => {
         await this.setState({
             colorFiltro: ''
         })
         this.filtrar();
     }
 
     filtrarSubcategoria = async (subcategoria) => {
         await this.setState({
             subcategoriaFiltro: subcategoria
         })
         this.filtrar();
     }
 
     quitarFiltroSubcategorias = async () => {
         await this.setState({
             subcategoriaFiltro: ''
         })
         this.filtrar();
     }
 
     filtrarPrecio = async (minPrecio, maxPrecio) => {
         await this.setState({
             minPrecioFiltro: minPrecio,
             maxPrecioFiltro: maxPrecio
         })
         this.filtrar();
     }
 
     quitarFiltroPrecio = async () => {
         await this.setState({
             minPrecioFiltro: '',
             maxPrecioFiltro: ''
         })
         this.filtrar();
     }
 
     filtrarDisponibles = async (disponibles) => {
         await this.setState({
             disponiblesFiltro: disponibles
         })
         this.filtrar();
     }

    render() {

        return ( 
            <>
                <div className="container-fluid">
                    <div className="row d-flex justify-content-center p-0 mt-2">
                        <div className="col-12">
                            <div style={{ backgroundImage: "url('/img/bannerProductosTemporada.jpg')", height: '100px', borderRadius: '7px' }} className='d-flex justify-content-center align-items-center'>
                                <h1 className='text-white'>Productos {this.props.titulo}</h1>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.dataReady ?
                            <Categoria 
                            data={this.state.parents}
                            colores={this.state.colores} 
                            subcategorias={this.state.subcategorias} 
                            filtrarColor={this.filtrarColor} 
                            quitarFiltroColores={this.quitarFiltroColores}
                            filtrarSubcategoria={this.filtrarSubcategoria} 
                            quitarFiltroSubcategorias={this.quitarFiltroSubcategorias}
                            filtrarPrecio={this.filtrarPrecio}
                            quitarFiltroPrecio={this.quitarFiltroPrecio}
                            filtrarDisponibles={this.filtrarDisponibles}
                            />
                        :
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center mt-5 mb-5">
                                <div style={{ backgroundColor: '#53565C', width: 'fit-content', padding: '20px', borderRadius: '10px' }}>
                                    <SpinnerInfinity size={200} thickness={70} speed={150} color="rgba(206, 6, 5, 1)" secondaryColor="rgba(255, 255, 255, 1)"/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
         );
    }
}
 
export default Temporada;