import React, { Component } from 'react';
import '../../css/catalogos.css';

class Catalogos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            catalogos: []
        }
    }

    componentDidMount = async () => {
        const requestOptionsCatalogo = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        const responseCatalogo = await fetch('https://www.argpromocionales.com/php/catalogosPDF.php', requestOptionsCatalogo);
        var dataCatalogo = await responseCatalogo.json();
        dataCatalogo = Object.values(dataCatalogo);
        dataCatalogo.forEach((element, index) => {
            dataCatalogo[index] = element.substr(0, element.indexOf('.'));
        });
        let uniqueCatalogos = [...new Set(dataCatalogo)];
        this.setState({
            catalogos: uniqueCatalogos
        })
    }
   
    render() { 
        return ( 
            <>
                <div className="container-fluid catalogos">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1>Catálogos</h1>
                        </div>
                    </div>
                    <div className="row pt-0 d-flex justify-content-center">
                        {
                            this.state.catalogos.map((e, index) =>
                                <div className="col-12 col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-4 px-4" key={index}>
                                    <a href={'/catalogos/' + e + '.pdf'} target='_blank' rel='noreferrer' aria-label={'Abrir catalogo ' + e} style={{ textDecoration: 'none', color: 'black' }}>
                                        <div className="bannerCatalogo d-flex justify-content-center align-items-center">
                                            <img src={'/catalogos/' + e + '.webp'} loading="lazy" alt="" width='100%' height="100%" style={{ borderRadius: '7px' }}/>
                                        </div>
                                    </a>
                                </div>
                            )
                        }
                    </div>
                </div>
            </>
         );
    }
}
 
export default Catalogos;