import React from 'react';
import '../../Components/Productos/producto.css';
import ImageGallery from 'react-image-gallery';
import { Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { SpinnerCircularFixed } from 'spinners-react';

class ProductoPO extends React.Component {
    constructor() {
        super();
        this.state = {
            fichaTecnica: [],
            existencias: [],
            imgs: [],
            colores: [],
            imgGeneral: '',
            tieneFichaTecnica: false,
            dataReady: false
        }
    }
 
    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'fichaTecnica', itemCode: this.props.codigoEspecifico })
        };
        const response = await fetch('https://us-central1-garteel-816a5.cloudfunctions.net/app/argpromocionales/api', requestOptions);
        const data = await response.json();
        if (data.resultado[0]) {
            this.setState({
                fichaTecnica: data.resultado[0],
                tieneFichaTecnica: true
            });
        }else{
            const requestOptionsDatos = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ 
                    operacion: 'getFichaPO',
                    codigoEspecifico: this.props.codigoEspecifico
                })
            };
            const responseDatos = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptionsDatos);
            const dataDatos = await responseDatos.json();
            let datos = dataDatos.datos;
            this.setState({
                fichaTecnica: datos[0],
                tieneFichaTecnica: false
            });
        }
        if (this.state.tieneFichaTecnica) {
            document.title = 'ARG - ' + this.capitalizar(this.state.fichaTecnica.Des1);
        }else{
            document.title = 'ARG - ' + this.capitalizar(this.obtenerNombre(this.state.fichaTecnica.description));
        }
        this.cargarImgs();
        this.cargarColores();
        this.cargarExistencias();
    }
 
    cargarImgs = async () => {
        if (this.props.codigoGeneral !== '' && this.props.codigoGeneral !== null) {
            let imgs = [];
            let imgGral = window.location.origin + '/img/ProductosPO/General/' + this.props.codigoGeneral.replaceAll(' ', '-') + '.jpg';
            let imgVector = window.location.origin + '/img/ProductosPO/Vectores/' + this.props.codigoGeneral.replaceAll(' ', '-') + '.jpg';
            let imgBuscar = this.props.codigoGeneral.replaceAll(' ', '-') + '.jpg';
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'existe', ruta: imgBuscar })
            };
            const response = await fetch('https://www.argpromocionales.com/php/existeImagen.php', requestOptions);
            const data = await response.json();
            if (data) {
                imgs[0] = {
                    original: imgGral,
                    originalHeight : '400px',
                    thumbnail: imgGral,
                    thumbnailTitle: 'Principal'
                };
            }else{
                const requestOptionsDatos = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        operacion: 'getFichaPO',
                        codigoEspecifico: this.props.codigoEspecifico
                    })
                };
                const responseDatos = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptionsDatos);
                const dataDatos = await responseDatos.json();
                let datos = dataDatos.datos;
                imgs[0] = {
                    original: datos[0].img,
                    originalHeight : '400px',
                    thumbnail: datos[0].img,
                    thumbnailTitle: 'Principal'
                };
            }
            const requestOptionsVectores = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'existe', ruta: imgBuscar })
            };
            const responseVectores = await fetch('https://www.argpromocionales.com/php/existeImagenVectores.php', requestOptionsVectores);
            const dataVectores = await responseVectores.json();
            if (dataVectores) {
                imgs[1] = {
                    original: imgVector,
                    originalHeight : '400px',
                    thumbnail: imgVector,
                    thumbnailTitle: 'Vectores'
                };
            }
            this.setState({
                imgs: imgs,
                imgGeneral: imgGral,
            })
        }
    }

    changeImg = (img) => {
        if (img === this.state.imgs[0].original) {
            let imgs = this.state.imgs;
            imgs[0].original = this.state.imgGeneral;
            imgs[0].thumbnail = this.state.imgGeneral;
            this.setState({
                imgs: imgs
            })
        }else{
            let imgs = this.state.imgs;
            imgs[0].original = img;
            imgs[0].thumbnail = img;
            this.setState({
                imgs: imgs
            })
        }
    }

    cargarColores = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'getColores',
                codigoGeneral: this.props.codigoGeneral
            })
          };
        const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
        const data = await response.json();
        this.setState({
            colores: data.colores
        });
    }

    cargarExistencias = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'getProductosPO',
                codigoGeneral: this.props.codigoGeneral
            })
        };
        const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
        const data = await response.json();
        let datos = data.productos;
        let existencias = [];
        datos.forEach(async (element, index) => {
            const requestOptionsEx = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ operacion: 'existencias', itemCode: element.codigoEspecifico })
            };
            const responseEx = await fetch('https://us-central1-garteel-816a5.cloudfunctions.net/app/argpromocionales/api', requestOptionsEx);
            const dataEx = await responseEx.json();
            let datosEx = Object.values(dataEx);
            datosEx = datosEx[0].Existencia;
            let exGDL = datosEx[0].Stok;
            let exCDMX = datosEx[1].Stok;
            existencias[index] = {
                codigoEspecifico: element.codigoEspecifico,
                color: element.color,
                exGDL: exGDL,
                exCDMX: exCDMX
            }
            this.setState({
                existencias: existencias,
                dataReady: true,
            })
        });
        this.setState({
            dataReady: true
        })
    }

    capitalizar = (color) => {
        const arr = color.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === '0.00') {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    numberWithCommas2 = (x) => {
        x = Number(x);
        if (x === 0) {
            return '0';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    obtenerDescripcion = (texto) => {
        let descripcion = ''
        if (texto) {
                descripcion = texto.slice(
                texto.indexOf('(') + 1,
                texto.lastIndexOf(')')
            ); 
        }
        return descripcion
    }

    sentenceCase(input, lowercaseBefore) {
        input = ( input === undefined || input === null ) ? '' : input;
        if (lowercaseBefore) { input = input.toLowerCase(); }
        return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
            return separator + char.toUpperCase();
        });
    }

    obtenerNombre = (texto) => {
        let nombre = '';
        if (texto) {
            nombre = texto.substring(0, texto.indexOf('('));
        }
        return nombre
    }
 
    render() {
        return (
            <>
                {
                    this.state.dataReady ?
                        <div className='container-fluid px-xl-5'>
                            <div className="row px-0">
                                <div className="col-12 text-center">
                                    <h1 style={{ textTransform: 'uppercase' }}>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.Des1 : this.obtenerNombre(this.state.fichaTecnica.description) }</h1>
                                </div>
                            </div>
                            <div className='row px-0'>
                                <div className='ccol-md-4 col-xl-4 px-xl-5 d-none d-xl-block'>
                                    <div className="caja">Características</div>
                                    <h5 style={{ marginLeft: '10px', marginTop: '10px' }}>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.Des2 : this.sentenceCase(this.obtenerDescripcion(this.state.fichaTecnica.description)) }</h5>
                                    <div className="caja">Especificaciones</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }}>Código</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline' style={{ color: '#efefef' }}>PO - </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.codigoGeneral}</p>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '145px' }}>Categoría</td>
                                                <td style={{ textTransform: 'capitalize' }}>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.Familia.toLowerCase() : this.state.fichaTecnica.family}</td>
        
                                            </tr>
                                            <tr>
                                                <td>Material</td>
                                                <td style={{ textTransform: 'capitalize' }}>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.Material.toLowerCase() : this.state.fichaTecnica.material}</td>
                                            </tr>
                                            <tr>
                                                <td>Medida</td>
                                                <td>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.Size : this.state.fichaTecnica.size}</td>
                                            </tr>
                                            <tr>
                                                <td>Capacidad</td>
                                                <td>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.Capacidad : this.state.fichaTecnica.capacity}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Impresión</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }} className="px-0 mx-0">Técnica de Impresión</td>
                                                <td style={{ textTransform: 'capitalize' }}>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.TecnicaImp : this.state.fichaTecnica.printing}</td>
                                            </tr>
                                            <tr>
                                                <td>Área</td>
                                                <td>{this.state.tieneFichaTecnica ? this.state.fichaTecnica.AreaImp : this.state.fichaTecnica.printing_area}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Empaque</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }}>Medida</td>
                                                <td>{
                                                    this.state.tieneFichaTecnica ? 
                                                        (Number(this.state.fichaTecnica.Length)*100).toFixed(2) + ' x ' + (Number(this.state.fichaTecnica.Height)*100).toFixed(2) + ' x ' + (Number(this.state.fichaTecnica.Width)*100).toFixed(2)
                                                    :
                                                        (Number(this.state.fichaTecnica.length)*100).toFixed(2) + ' x ' + (Number(this.state.fichaTecnica.height)*100).toFixed(2) + ' x ' + (Number(this.state.fichaTecnica.width)*100).toFixed(2)
                                                } cm</td>
                                            </tr>
                                            <tr>
                                                <td>Peso</td>
                                                <td>{
                                                    this.state.tieneFichaTecnica &&
                                                        (Number(this.state.fichaTecnica.Weight)).toFixed(2) + ' Kg.'     
                                                    }</td>
                                            </tr>
                                            <tr>
                                                <td>Cantidad de Pzas.</td>
                                                <td>{
                                                        this.state.tieneFichaTecnica ?
                                                            (Number(this.state.fichaTecnica.Empaque)).toFixed()
                                                        :
                                                            (Number(this.state.fichaTecnica.count_box)).toFixed()
                                                    }</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 col-xl-4 px-xl-4">
                                    <div className='row m-0 p-0'>
                                        {
                                            this.state.imgs !== '' &&
                                                <ImageGallery items={this.state.imgs} useBrowserFullscreen={false} showPlayButton={false} showNav={false} showFullscreenButton={false} onErrorImageURL={window.location.origin + '/img/noImage.jpg'}/>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            {this.state.colores.map((e, index) =>
                                                <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                key={index}
                                                placement={'top'}
                                                overlay={
                                                    <Popover id={`popover-positioned-top`}>
                                                    <Popover.Body>
                                                        {this.capitalizar(e.color)}
                                                    </Popover.Body>
                                                    </Popover>
                                                }
                                                >
                                                <button className={'btnColor mx-1 btnColor' + e.btnColor} onClick={ () => this.changeImg(e.img)}></button>
                                                </OverlayTrigger>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-xl-4 px-xl-5 d-xl-none'>
                                    <div className="caja">Características</div>
                                    <h5 style={{ marginLeft: '10px', marginTop: '10px' }}><div dangerouslySetInnerHTML={{ __html: this.state.fichaTecnica.Des2 }}/></h5>
                                    <div className="caja">Especificaciones</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td>Código</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline' style={{ color: '#efefef' }}>PO - </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.codigoGeneral}</p>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Categoría</td>
                                                <td><div dangerouslySetInnerHTML={{ __html: this.state.fichaTecnica.Familia }}/></td>
        
                                            </tr>
                                            <tr>
                                                <td>Material</td>
                                                <td><div dangerouslySetInnerHTML={{ __html: this.state.fichaTecnica.Material }}/></td>
                                            </tr>
                                            <tr>
                                                <td>Medida</td>
                                                <td>{this.state.fichaTecnica.Size}</td>
                                            </tr>
                                            <tr>
                                                <td>Capacidad</td>
                                                <td>{this.state.fichaTecnica.Capacidad}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Impresión</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }} className="px-0 mx-0">Técnica de Impresión</td>
                                                <td><div dangerouslySetInnerHTML={{ __html: this.state.fichaTecnica.TecnicaImp }}/></td>
                                            </tr>
                                            <tr>
                                                <td>Área</td>
                                                <td>{this.state.fichaTecnica.AreaImp}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Empaque</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }}>Medida</td>
                                                <td>{(Number(this.state.fichaTecnica.Length)*100).toFixed(2)} x {(Number(this.state.fichaTecnica.Height)*100).toFixed(2)} x {(Number(this.state.fichaTecnica.Width)*100).toFixed(2)} cm</td>
                                            </tr>
                                            <tr>
                                                <td>Peso</td>
                                                <td>{(Number(this.state.fichaTecnica.Weight)).toFixed(2)} Kg.</td>
                                            </tr>
                                            <tr>
                                                <td>Cantidad de Pzas.</td>
                                                <td>{(Number(this.state.fichaTecnica.Empaque)).toFixed()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 col-xl-4 px-xl-5">
                                    <h3 className='text-center theadProducto'>Disponibilidad</h3>
                                    <table className='w-100 table table-hover'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>Clave</th>
                                                <th style={{ width: '25%' }}>Color</th>
                                                <th className='text-center' style={{ width: '25%' }}>CDMX (Pzas.)</th>
                                                <th className='text-center' style={{ width: '25%' }}>GDL (Pzas.)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.existencias.map((e,index)=>
                                                    <tr key={index}>
                                                        <td>{e.codigoEspecifico}</td>
                                                        <td>{this.capitalizar(e.color)}</td>
                                                        <td className='text-center'>{this.numberWithCommas2(e.exCDMX)}</td>
                                                        <td className='text-center'>{this.numberWithCommas2(e.exGDL)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>                                
                            </div>
                        </div>
                    :
                        <div className='container-fluid px-xl-5'>
                            <div className="row p-5">
                                <div className="col-12 text-center p-5">
                                    <SpinnerCircularFixed size={130} thickness={70} speed={200} color="rgba(201, 13, 12, 1)" secondaryColor="rgba(164, 168, 177, 1)" />
                                </div>
                            </div>
                        </div>
                } 
            </>
        );
    }
}
 
export default ProductoPO;