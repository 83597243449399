import React from 'react';
import '../../css/footer.css';
import logo from '../../images/logoaBlanco.webp';

class Footer extends React.Component {
    constructor(){
        super();
        this.state = {
            style: []
        }
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.getHeightFooter)
    }

    getHeightFooter = () => {
        var elemento = document.getElementById('footer');
        let styleInput = window.getComputedStyle(elemento);
        let height = styleInput.getPropertyValue('height');
        document.getElementsByTagName("body")[0].style.marginBottom = height;
    }

    render() { 
        return (
            <>            
            <div className="container-fluid footer" id='footer' onLoad={this.getHeightFooter}>
              <footer className='p-0 px-lg-5'>
                    <div className="row">
                      <div className="col-lg-3 col-md-3 col-12 d-flex align-items-center justify-content-center justify-content-md-start">
                        <a href='/'>
                            <img src={logo} className="imgf" alt="logo" height="80px" width="189px"/>
                        </a>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-12 text-center text-md-start">
                        <h1 className="text-title mb-3">Información</h1>
                        <ul className="list-unstyled">
                          <li><a href="/somos" className="text-white">Acerca de nosotros</a></li>
                          <li><a href="/impresion" className="text-white">Servicios</a></li>
                          <li><a href="/contacto" className="text-white">Contacto</a></li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 col-12 text-center text-md-start">
                        <h1 className="text-title mb-3">Redes sociales</h1>
                        <ul className="list-unstyled">
                          <li><i className="bi bi-facebook"></i><a href="https://www.facebook.com/profile.php?id=100078723923333" className="text-white px-2">  ARG Promocionales</a></li>
                          <li><i className="bi bi-instagram"></i><a href="https://www.instagram.com/arg.promocionales" className="text-white px-2"> arg.promocionales</a></li>
                          <li><i className="bi bi-whatsapp"></i><a href="https://wa.me/527714733271" className="text-white px-2">+52 771 473 3271</a></li>
                        </ul>
                      </div>
                      <div className="col-lg-3 col-md-3 col-12 d-flex align-items-center justify-content-center">
                        <h1 style={{textAlign: "center", fontSize: '24px'}} className="text-logo mb-3">"TU MAYORISTA EN PROMOCIONALES"</h1>
                      </div>
                    </div>
              </footer>
            </div>
            </>
        );
    }
}
 
export default Footer;