import React from 'react';
import '../../Components/Productos/producto.css';
import ImageGallery from 'react-image-gallery';
import { Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { SpinnerCircularFixed } from 'spinners-react';
// import iconDescuento from '../../images/iconDescuento.png';

class ProductoDV extends React.Component {
    constructor() {
        super();
        this.state = {
            fichaTecnica: [],
            existencias: [],
            imgs: [],
            colores: [],
            imgGeneral: '',
            dataReady: false
        }
    }
 
    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ operacion: 'fichaTecnicaDV', itemCode: this.props.codigoGeneral })
        };
        const response = await fetch('https://www.argpromocionales.com/php/webservicexml.php', requestOptions);
        const data = await response.json();
        this.setState({
            fichaTecnica: data.resultado[0]
        });
        document.title = 'ARG - ' + this.capitalizar(this.obtenerNombre(this.state.fichaTecnica.NOMBRE).toLowerCase());
        this.cargarImgs();
        this.cargarColores();
        this.cargarExistencias(data.resultado);
    }
 
    cargarImgs = () => {
        let imgs = [];
        let imgGral = 'https://doblevela.com/images/large/' + this.props.codigoGeneral.replaceAll(' ', '-') + '_lrg.jpg';
        imgs[0] = {
            original: imgGral,
            originalHeight : '400px',
            thumbnail: imgGral,
            thumbnailTitle: 'Principal'
        };
        this.setState({
            imgs: imgs,
            imgGeneral: imgGral,
        })
    }

    changeImg = (img) => {
        if (img === this.state.imgs[0].original) {
            let imgs = this.state.imgs;
            imgs[0].original = this.state.imgGeneral;
            imgs[0].thumbnail = this.state.imgGeneral;
            this.setState({
                imgs: imgs
            })
        }else{
            let imgs = this.state.imgs;
            imgs[0].original = img;
            imgs[0].thumbnail = img;
            this.setState({
                imgs: imgs
            })
        }
    }

    cargarColores = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'getColores',
                codigoGeneral: this.props.codigoGeneral
            })
          };
        const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
        const data = await response.json();
        this.setState({
            colores: data.colores
        });
    }

    cargarExistencias = async (datos) => {
        let existencias = [];
        datos.forEach((element, index) => {
            existencias[index] = {
                codigoEspecifico: element.CLAVE,
                color: element.COLOR.split(' ').slice(2).join(' ').toLowerCase(),
                existencias: element.EXISTENCIAS
            }
        });
        this.setState({
            existencias: existencias,
            dataReady: true
        })
    }

    capitalizar = (color) => {
        const arr = color.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === '0.00') {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    numberWithCommas2 = (x) => {
        x = Number(x);
        if (x === 0) {
            return '0';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    obtenerDescripcion = (texto) => {
        let descripcion = ''
        if (texto) {
            descripcion = texto.split('CAP.')[0];
        }
        return descripcion
    }

    sentenceCase(input, lowercaseBefore) {
        input = ( input === undefined || input === null ) ? '' : input;
        if (lowercaseBefore) { input = input.toLowerCase(); }
        return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
            return separator + char.toUpperCase();
        });
    }

    obtenerNombre = (texto) => {
        let nombre = '';
        if (texto) {
            nombre = texto.split(this.props.codigoGeneral)[0];
        }
        return nombre
    }

    obtenerCapacidad = (texto) => {
        let capacidad = ''
        if (texto) {
            if (texto.includes('CAP.')) {
                capacidad = texto.split('CAP.')[1];
                capacidad = capacidad.replace(' ', '');
                capacidad = capacidad.replace('.', '');   
            }
        }
        return capacidad
    }

    obtenerTiposImpresion = (tipos) => {
        let arrayTipos = tipos.split(' ');
        let tiposImpresion = [];
        arrayTipos.forEach(element => {
            switch (element) {
                case 'SE':
                    tiposImpresion.push('Serigrafía');
                break;
                case 'BR':
                    tiposImpresion.push('Bordado');
                    break;
                case 'BP':
                    tiposImpresion.push('Brand Pach');
                    break;
                case 'DTFT':
                    tiposImpresion.push('DTF Textil');
                    break;
                case 'FC':
                    tiposImpresion.push('Full Color');
                    break;
                case 'GR':
                    tiposImpresion.push('Gota Resina');
                    break;
                case 'GRR':
                    tiposImpresion.push('Gota Resina Relieve');
                    break;
                case 'GRT':
                    tiposImpresion.push('Gota Resina Textil');
                    break;
                case 'GS':
                    tiposImpresion.push('Grabado En Seco');
                    break;
                case 'GL':
                    tiposImpresion.push('Grabado Láser');
                    break;
                case 'HS':
                    tiposImpresion.push('Hot Stamping');
                    break;
                case 'SB':
                    tiposImpresion.push('Sand Blast');
                    break;
                case 'SUB':
                    tiposImpresion.push('Sublimación');
                    break;
                case 'TM':
                    tiposImpresion.push('Tampografía');
                    break;
                case 'TC':
                    tiposImpresion.push('Termocalca');
                    break;
                case 'TCC':
                    tiposImpresion.push('Termocalca Cartón');
                    break;
                case 'TR':
                    tiposImpresion.push('Transfer');
                    break;
                case 'VTEX':
                    tiposImpresion.push('Vinil textil');
                    break;
                case 'VT':
                    tiposImpresion.push('Vitrificado');
                    break;
                default:
                    tiposImpresion.push(element);
                    break;
            }
        });
        return tiposImpresion.join(', ')
    }
 
    render() {
        return (
            <>
                {
                    this.state.dataReady ?
                        <div className='container-fluid px-xl-5'>
                            <div className="row px-0">
                                <div className="col-12 text-center">
                                    <h1 style={{ textTransform: 'uppercase' }}>{this.obtenerNombre(this.state.fichaTecnica.NOMBRE) }</h1>
                                </div>
                            </div>
                            <div className='row px-0'>
                                <div className='col-md-4 col-xl-4 px-xl-5 d-none d-xl-block'>
                                    <div className="caja">Características</div>
                                    <h5 style={{ marginLeft: '10px', marginTop: '10px' }}>{this.sentenceCase(this.obtenerDescripcion(this.state.fichaTecnica.Descripcion)) }</h5>
                                    <div className="caja">Especificaciones</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }}>Código</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline' style={{ color: '#efefef' }}>DV - </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.codigoGeneral}</p>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ width: '145px' }}>Categoría</td>
                                                <td style={{ textTransform: 'capitalize' }}>{this.props.subcategoria}</td>
        
                                            </tr>
                                            <tr>
                                                <td>Material</td>
                                                <td style={{ textTransform: 'capitalize' }}>{this.state.fichaTecnica.Material}</td>
                                            </tr>
                                            <tr>
                                                <td>Medida</td>
                                                <td>{this.state.fichaTecnica['Medida Producto'].replace('Ø', '')}</td>
                                            </tr>
                                            <tr>
                                                <td>Capacidad</td>
                                                <td>{this.obtenerCapacidad(this.state.fichaTecnica.Descripcion)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Impresión</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }} className="px-0 mx-0">Técnica de Impresión</td>
                                                <td style={{ textTransform: 'capitalize' }}>{this.obtenerTiposImpresion(this.state.fichaTecnica['Tipo Impresion'])}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Empaque</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }}>Medida</td>
                                                <td>{this.state.fichaTecnica['Medida Caja Master']}</td>
                                            </tr>
                                            <tr>
                                                <td>Peso</td>
                                                <td>{this.state.fichaTecnica['Peso caja']}</td>
                                            </tr>
                                            <tr>
                                                <td>Cantidad de Pzas.</td>
                                                <td>{this.state.fichaTecnica['Unidad Empaque']}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 col-xl-4 px-xl-4">
                                    <div className='row m-0 p-0'>
                                        {
                                            this.state.imgs !== '' &&
                                                <ImageGallery items={this.state.imgs} useBrowserFullscreen={false} showPlayButton={false} showNav={false} showFullscreenButton={false} onErrorImageURL={window.location.origin + '/img/noImage.jpg'}/>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            {this.state.colores.map((e, index) =>
                                                <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                key={index}
                                                placement={'top'}
                                                overlay={
                                                    <Popover id={`popover-positioned-top`}>
                                                    <Popover.Body>
                                                        {this.capitalizar(e.color)}
                                                    </Popover.Body>
                                                    </Popover>
                                                }
                                                >
                                                <button className={'btnColor mx-1 btnColor' + e.btnColor} onClick={ () => this.changeImg(e.img)}></button>
                                                </OverlayTrigger>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-xl-4 px-xl-5 d-xl-none'>
                                    <div className="caja">Características</div>
                                    <h5 style={{ marginLeft: '10px', marginTop: '10px' }}>{this.sentenceCase(this.obtenerDescripcion(this.state.fichaTecnica.Descripcion)) }</h5>
                                    <div className="caja">Especificaciones</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td>Código</td>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline' style={{ color: '#efefef' }}>DV - </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.codigoGeneral}</p>                                                    
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Categoría</td>
                                                <td>{this.props.subcategoria}</td>
        
                                            </tr>
                                            <tr>
                                                <td>Material</td>
                                                <td>{this.state.fichaTecnica.Material}</td>
                                            </tr>
                                            <tr>
                                                <td>Medida</td>
                                                <td>{this.state.fichaTecnica['Medida Producto'].replace('Ø', '')}</td>
                                            </tr>
                                            <tr>
                                                <td>Capacidad</td>
                                                <td>{this.obtenerCapacidad(this.state.fichaTecnica.Descripcion)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Impresión</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }} className="px-0 mx-0">Técnica de Impresión</td>
                                                <td>{this.obtenerTiposImpresion(this.state.fichaTecnica['Tipo Impresion'])}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="caja">Información de Empaque</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '145px' }}>Medida</td>
                                                <td>{this.state.fichaTecnica['Medida Caja Master']}</td>
                                            </tr>
                                            <tr>
                                                <td>Peso</td>
                                                <td>{this.state.fichaTecnica['Peso caja']}</td>
                                            </tr>
                                            <tr>
                                                <td>Cantidad de Pzas.</td>
                                                <td>{this.state.fichaTecnica['Unidad Empaque']}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="col-md-4 col-xl-4 px-xl-5">
                                    <h3 className='text-center theadProducto'>Disponibilidad</h3>
                                    <table className='w-100 table table-hover'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>Clave</th>
                                                <th style={{ width: '25%' }}>Color</th>
                                                <th className='text-center' style={{ width: '25%' }}>Existencia (Pzas.)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.existencias.map((e,index)=>
                                                    <tr key={index}>
                                                        <td>{e.codigoEspecifico}</td>
                                                        <td>{this.capitalizar(e.color)}</td>
                                                        <td className='text-center'>{this.numberWithCommas2(e.existencias)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                
                                 
                            </div>
                        </div>
                    :
                        <div className='container-fluid px-xl-5'>
                            <div className="row p-5">
                                <div className="col-12 text-center p-5">
                                    <SpinnerCircularFixed size={130} thickness={70} speed={200} color="rgba(201, 13, 12, 1)" secondaryColor="rgba(164, 168, 177, 1)" />
                                </div>
                            </div>
                        </div>
                } 
            </>
        );
    }
}
 
export default ProductoDV;