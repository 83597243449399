import React from "react";
import "../Paginas/somos.css";
import { useEffect } from "react";
import v3 from '../images/apmanos.png';
import v2 from '../images/positiva.png';
import v4 from '../images/resp.png';
function Somos() {
  useEffect(() => {
    document.title = "ARG - Nosotros";
  }, []);

  return (
    <>
      <div className="ABOUT">
        <div className="about-1">
          <h1> ¿Quienes somos? </h1>
        </div>
        <div className="about-2">
          <div className="content-box-lg ">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4 py-5">
                  <div className="ms-auto me-auto about-item text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"  className="bi bi-people" viewBox="0 0 16 16">
                      <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8Zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022ZM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816ZM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4Z"/>
                    </svg>
                    <h3>¿Quienes somos?</h3>
                    <hr />
                    <p>
                      {" "}
                      Somos una empresa líder en el ramo de artículos
                      promocionales que ofrece un servicio personalizado de
                      acuerdo a las necesidades de nuestros clientes, la calidad
                      de nuestros productos nos destaca de nuestra competencia,
                      somos una empresa profesional que ofrece el mejor
                      costo-beneficio para diferenciar a tu marca.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-5">
                  <div className="ms-auto me-auto about-item text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"  className="bi bi-journals" viewBox="0 0 16 16">
                      <path d="M5 0h8a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2 2 2 0 0 1-2 2H3a2 2 0 0 1-2-2h1a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1H1a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v9a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1H3a2 2 0 0 1 2-2z"/>
                      <path d="M1 6v-.5a.5.5 0 0 1 1 0V6h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V9h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 2.5v.5H.5a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1H2v-.5a.5.5 0 0 0-1 0z"/>
                    </svg>
                    <h3>Misión</h3>
                    <hr />
                    <p>
                      {" "}
                      En ARG Promocionales tenemos como misión que nuestros
                      clientes consoliden su marca ofreciendo artículos
                      promocionales de primera calidad a sus consumidores.{" "}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-5">
                  <div className="ms-auto me-auto about-item text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60"  className="bi bi-globe-americas" viewBox="0 0 16 16">
                      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM2.04 4.326c.325 1.329 2.532 2.54 3.717 3.19.48.263.793.434.743.484-.08.08-.162.158-.242.234-.416.396-.787.749-.758 1.266.035.634.618.824 1.214 1.017.577.188 1.168.38 1.286.983.082.417-.075.988-.22 1.52-.215.782-.406 1.48.22 1.48 1.5-.5 3.798-3.186 4-5 .138-1.243-2-2-3.5-2.5-.478-.16-.755.081-.99.284-.172.15-.322.279-.51.216-.445-.148-2.5-2-1.5-2.5.78-.39.952-.171 1.227.182.078.099.163.208.273.318.609.304.662-.132.723-.633.039-.322.081-.671.277-.867.434-.434 1.265-.791 2.028-1.12.712-.306 1.365-.587 1.579-.88A7 7 0 1 1 2.04 4.327Z"/>
                    </svg>
                    <h3>Visión</h3>
                    <hr />
                    <p>
                      {" "}
                      Ser la empresa líder en Latinoamérica y la mejor opción
                      para las pequeñas, medianas y grandes empresas que buscan
                      impulsar su marca. Ayudar a microempresarios comprometidos
                      con programas de apoyo para dar a conocer su marca.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="valores">
            <h1>Valores</h1>
        </div>
        <div className="about-3">
          <div className="content-box-lg justify-content-center">
            <div className="container ">
              <div className="row justify-content-center">
                <div className="col-12 col-md-6 col-lg-4 py-5 justify-content-center ">
                  <div className="ms-auto me-auto about-item2 text-center">
                  <img src={v2} className="bi" alt=""/>
                    <h3><strong>A</strong>ctitud de servicio</h3>
                    <hr />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-5">
                  <div className="ms-auto me-auto about-item2 text-center">
                  <img src={v4} className="bi" alt="" />
                    <h3><strong>R</strong>esponsabilidad</h3>
                    <hr />
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4 py-5">
                  <div className="ms-auto me-auto about-item2 text-center">
                  <img src={v3} className="bi" alt=""/>
                    <h3><strong>G</strong>entileza</h3>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Somos;
