import React from "react";
import "../css/menu.css";
import logo from "../images/logoaBlanco.webp"
import iconDescuento from '../images/iconDescuentoBlanco.webp';
import Barbus from "../Components/Barbus";

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tituloTemporada: "",
      rutaTemporada: "",
    };
  }

  componentDidMount = async () => {
    document.addEventListener("DOMContentLoaded", function () {
      document.querySelectorAll(".dropdown-menu").forEach(function (element) {
        element.addEventListener("click", function (e) {
          e.stopPropagation();
        });
      });
    });
    const requestOptionsTemporada = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };
    const responseTemporada = await fetch(
      "https://www.argpromocionales.com/php/productosTemporada.php",
      requestOptionsTemporada
    );
    const dataTemporada = await responseTemporada.json();
    if (dataTemporada.titulo) {
      this.setState({
        rutaTemporada: dataTemporada.titulo.replaceAll(" ", "-").toLowerCase(),
        tituloTemporada: dataTemporada.titulo,
      });
    }
  };

  render() {
    return (
      <>
        <div className="home">
          <nav className="navbar navbar-expand-lg navbar-dark ">
          <button className="navbar-toggler ms-auto me-auto" type="button" data-bs-toggle="collapse" aria-label="Boton de menu" data-bs-target="#main_nav">
              <span className="iconoToggler"><i className="bi bi-list"></i></span>
            </button>
            {/* Pendiente logo-boton grande */}
            <a className="navbar-brand ms-auto me-auto " href="/">
                <img src={logo} alt="logo" width="120px" height="100%"/>
            </a>                
            <ul className="m-0 p-0 mx-auto mt-4 unstyled">
              <li className="search-bar ms-auto me-auto">
                <Barbus/>
              </li>
            </ul>
            <div className="collapse navbar-collapse" id="main_nav">
              <hr className="text-light" />
              <ul className="navbar-nav text-center">
                <li className="nav-item dropdown has-megamenu">
                  <a
                    className="nav-link dropdown-toggle"
                    href="/#"
                    data-bs-toggle="dropdown"
                  >
                    Productos
                  </a>
                  <div className="dropdown-menu megamenu" role="menu">
                    <div className="row g-3">
                      <div className="col-lg-2 col-sm-6 col-12 colMenu">
                        <div className="col-megamenu px-1">
                          <h5 className="title">Bebidas</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/bebidas/cilindros?page=1">
                                Cilindros
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/bebidas/empaques?page=1">
                                Empaques
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/bebidas/tazas-y-termos?page=1">
                                Tazas y Termos
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/bebidas/vasos?page=1">
                                Vasos
                              </a>
                            </li>
                          </ul>
                          <h5 className="title mt-2">Cuidado Personal</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/cuidado-personal/antiestres?page=1">
                                Antiestrés
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/cuidado-personal/salud-y-belleza?page=1">
                                Salud y Belleza
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12 colMenu">
                        <div className="col-megamenu px-1">
                          <h5 className="title">Hogar y Herramientas</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/hogar-y-herramientas/accesorios-de-cocina?page=1">
                                Accesorios de Cocina
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/hogar-y-herramientas/articulos-del-hogar?page=1">
                                Artículos del Hogar
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/hogar-y-herramientas/automovil?page=1">
                                Automóvil
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/hogar-y-herramientas/herramientas?page=1">
                                Herramientas
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/hogar-y-herramientas/mascotas?page=1">
                                Mascotas
                              </a>
                            </li>
                          </ul>
                          <div className='d-none d-lg-block'>
                            <h5 className="title mt-2">Oficina</h5>
                            <ul className="list-unstyled">
                              <li><a href="/categorias/oficina/agendas?page=1">Agendas</a></li>
                              <li><a href="/categorias/oficina/articulos-de-oficina?page=1">Artículos de Oficina</a></li>
                              <li><a href="/categorias/oficina/boligrafos-ecologicos?page=1">Bolígrafos Ecológicos</a></li>
                              <li><a href="/categorias/oficina/boligrafos-metalicos?page=1">Bolígrafos Metálicos</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12 colMenu">
                        <div className="col-megamenu px-1">
                          <div className='d-block d-lg-none'>
                            <h5 className="title mt-2">Oficina</h5>
                            <ul className="list-unstyled">
                              <li><a href="/categorias/oficina/agendas?page=1">Agendas</a></li>
                              <li><a href="/categorias/oficina/articulos-de-oficina?page=1">Artículos de Oficina</a></li>
                              <li><a href="/categorias/oficina/boligrafos-ecologicos?page=1">Bolígrafos Ecológicos</a></li>
                              <li><a href="/categorias/oficina/boligrafos-metalicos?page=1">Bolígrafos Metálicos</a></li>
                            </ul>
                          </div>
                          <ul className="list-unstyled mt-2">
                            <li><a href="/categorias/oficina/boligrafos-multifuncion?page=1">Bolígrafos Multifunción</a></li>
                            <li><a href="/categorias/oficina/boligrafos-plasticos?page=1">Bolígrafos Plásticos</a></li>
                            <li><a href="/categorias/oficina/boligrafos-resaltador?page=1">Bolígrafos Resaltador</a></li>
                            <li><a href="/categorias/oficina/carpetas-y-libretas?page=1">Carpetas y Libretas</a></li>
                            <li><a href="/categorias/oficina/crayolas?page=1">Crayolas</a></li>
                            <li><a href="/categorias/oficina/estuches-de-regalo?page=1">Estuches de Regalo</a></li>
                            <li><a href="/categorias/oficina/lapices?page=1">Lápices</a></li>
                            <li><a href="/categorias/oficina/llaveros?page=1">Llaveros</a></li>
                            <li><a href="/categorias/oficina/set-de-boligrafos?page=1">Set de Bolígrafos</a></li>
                          </ul>
                        </div>  
                      </div> 


                      <div className="col-lg-2 col-sm-6 col-12 colMenu">
                        <div className="col-megamenu">
                          <h5 className="title">Sublimación</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/sublimacion/decorativos?page=1">
                                Decorativos
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/sublimacion/fundas?page=1">
                                Fundas
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/sublimacion/tazas-y-termos?page=1">
                                Tazas y Termos
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/sublimacion/textil?page=1">
                                Textil
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/sublimacion/varios?page=1">
                                Varios
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="col-megamenu px-1">
                          <h5 className="title">Tecnología</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/tecnologia/accesorios-celular-y-tablet?page=1">
                                Accesorios Celular y Tablet
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/tecnologia/accesorios-computo?page=1">
                                Accesorios Cómputo
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/tecnologia/accesorios-tecnologia?page=1">
                                Accesorios Tecnología
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/tecnologia/audio?page=1">
                                Audio
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/tecnologia/usb?page=1">
                                USB
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-2 col-sm-6 col-12 colMenu">
                        <div className="col-megamenu px-1">
                          <h5 className="title">Textiles</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/textiles/bolsas?page=1">
                                Bolsas
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/textiles/chamarras-y-chalecos?page=1">
                                Chamarras y Chalecos
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/textiles/gorras-y-sombreros?page=1">
                                Gorras y Sombreros
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/textiles/hieleras-y-loncheras?page=1">
                                Hieleras y Loncheras
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/textiles/maletas-maletines-y-mochilas?page=1">
                                Maletas Maletines y Mochilas
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/textiles/paraguas?page=1">
                                Paraguas
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/textiles/playeras?page=1">
                                Playeras
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 col-sm-6 col-12 colMenu"
                        style={{ borderRight: "none" }}
                      >
                        <div className="col-megamenu px-1">
                          <h5 className="title">Viaje y Recreación</h5>
                          <ul className="list-unstyled">
                            <li>
                              <a href="/categorias/viaje-y-recreacion/articulos-de-viaje?page=1">
                                Artículos de Viaje
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/viaje-y-recreacion/entretenimiento?page=1">
                                Entretenimiento
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/viaje-y-recreacion/tiempo-libre?page=1">
                                Tiempo Libre
                              </a>
                            </li>
                            <li>
                              <a href="/categorias/viaje-y-recreacion/trofeos?page=1">
                                Trofeos
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/impresion">
                    Impresión
                  </a>{" "}
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/somos">
                    Nosotros
                  </a>
                </li>
                
                
              </ul>
            </div>
            <div className='BotonDes ms-auto me-auto '> 
                  <a href="https://wa.me/527714733271" target="_blank" rel="noopener noreferrer"> 
                      <button className="ver-button" >
                          <div className="desp"><img src={iconDescuento} alt="" width={'100%'}/> DESCUENTO</div>
                          <div className="ver">
                            ¡Comunicate con un asesor y recibe un descuento adicional!
                          </div>
                     </button></a>
                 </div>
                
          </nav>
        </div>
      </>
    );
  }
}

export default Menu;
