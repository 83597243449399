import React from "react";
import Tarjeta from "../Tarjeta";
import { Popover } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import "react-input-range/lib/css/index.css";
import Pagination from "@mui/material/Pagination";

class Categoria extends React.Component {
  constructor() {
    super();
    this.params = new URLSearchParams(window.location.search);
    this.state = {
      data: [],
      hideColores: false,
      hideColorFiltrado: true,
      colorFiltro: "",

      hideSubcategorias: false,
      hideSubcategoriaFiltrada: true,
      subcategoriaFiltro: "",

      showModalFiltros: false,
      changingFiltros: false,

      noPaginas: 1,
    };
  }

  componentDidMount = () => {
    this.comprobarNumeroPaginas();
    if (this.params.get("color")) {
      this.setState({
        hideColores: true,
        hideColorFiltrado: false,
        colorFiltro: this.params.get("color"),
        changingFiltros: false,
      });
    }
    if (this.params.get("cat")) {
      this.setState({
        hideSubcategorias: true,
        hideSubcategoriaFiltrada: false,
        subcategoriaFiltro: this.params.get("cat"),
        changingFiltros: false,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.comprobarNumeroPaginas();
    }
  }

  comprobarNumeroPaginas = () => {
    let noPaginas = Math.ceil(this.props.data.length / 24);
    if (noPaginas !== 0) {
      if (this.params.get("page")) {
        if (Number(this.params.get("page"))) {
          if (
            Number(this.params.get("page")) > noPaginas ||
            Number(this.params.get("page")) < 0
          ) {
            this.setState({
              noPaginas: noPaginas,
            });
            this.params.set("page", 1);
            window.location.search = this.params.toString();
          } else {
            this.setState({
              noPaginas: noPaginas,
            });
          }
        } else {
          this.setState({
            noPaginas: noPaginas,
          });
          this.params.set("page", 1);
          window.location.search = this.params.toString();
        }
      } else {
        this.setState({
          noPaginas: noPaginas,
        });
        this.params.set("page", 1);
        window.location.search = this.params.toString();
      }
    } else {
      this.setState({
        noPaginas: noPaginas,
      });
    }
  };

  filtrarColor = (e) => {
    this.params.set("color", e.target.value);
    this.params.set("page", 1);
    window.location.search = this.params.toString();
  };

  quitarFiltroColores = () => {
    this.params.set("page", 1);
    this.params.delete("color");
    window.location.search = this.params.toString();
    this.setState({
      hideColores: false,
      hideColorFiltrado: true,
      colorFiltro: "",
      changingFiltros: false,
    });
  };

  filtrarSubcategoria = (e) => {
    this.params.set("cat", e.target.value);
    this.params.set("page", 1);
    window.location.search = this.params;
  };

  quitarFiltroSubcategorias = () => {
    this.params.set("page", 1);
    this.params.delete("cat");
    window.location.search = this.params.toString();
    this.setState({
      hideSubcategorias: false,
      hideSubcategoriaFiltrada: true,
      subcategoriaFiltro: "",
      changingFiltros: false,
    });
  };

  btnColor = (color) => {
    const arr = color.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join("");
    return str2;
  };

  capitalizar = (color) => {
    const arr = color.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  cerrarModal = () => {
    this.setState({ showModalFiltros: false });
  };

  abrirModal = () => {
    this.setState({
      showModalFiltros: true,
    });
  };

  handleChangePagination = (_, value) => {
    this.params.set("page", value);
    window.location.search = this.params.toString();
    this.setState({
      pagina: value,
    });
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="row">
          <div className="col-sm-3 filtrosEscritorio d-none d-lg-block">
            <h4 className="tituloFiltros mt-3">Colores</h4>
            <div hidden={this.state.hideColorFiltrado}>
              <OverlayTrigger
                trigger={["hover", "focus"]}
                key={"top"}
                placement={"top"}
                overlay={
                  <Popover id={`popover-positioned-top`}>
                    <Popover.Body>
                      {this.capitalizar(this.state.colorFiltro)}
                    </Popover.Body>
                  </Popover>
                }
              >
                <button
                  className={
                    "btnColor mx-1 btnColor" +
                    this.btnColor(this.state.colorFiltro)
                  }
                  type="button"
                />
              </OverlayTrigger>
            </div>
            <div hidden={this.state.hideColores}>
              {this.props.colores.map((e, index) => (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  key={index}
                  placement={"top"}
                  overlay={
                    <Popover id={`popover-positioned-top`}>
                      <Popover.Body>{this.capitalizar(e)}</Popover.Body>
                    </Popover>
                  }
                >
                  <button
                    className={"btnColor mx-1 btnColor" + this.btnColor(e)}
                    value={e}
                    aria-label={"Boton de filtro color " + e}
                    onClick={this.filtrarColor}
                  ></button>
                </OverlayTrigger>
              ))}
            </div>
            <div className="text-end">
              <button
                onClick={this.quitarFiltroColores}
                className="btnQuitarFiltros"
                hidden={this.state.hideColorFiltrado}
              >
                Quitar Filtro
              </button>
            </div>
            <hr />
            <h4 className="tituloFiltros mt-1">Tipo de Producto</h4>
            <div hidden={this.state.hideSubcategoriaFiltrada}>
              <button type="button" className="mx-1 btnFiltros">
                {this.capitalizar(this.state.subcategoriaFiltro)}
              </button>
            </div>
            <div hidden={this.state.hideSubcategorias}>
              {this.props.subcategorias.map((e, index) => (
                <div key={index} style={{ width: "100%" }}>
                  <button
                    value={e}
                    className="mx-1 btnFiltros"
                    onClick={this.filtrarSubcategoria}
                  >
                    {this.capitalizar(e)}
                  </button>
                </div>
              ))}
            </div>
            <div className="text-end">
              <button
                onClick={this.quitarFiltroSubcategorias}
                className="btnQuitarFiltros"
                hidden={this.state.hideSubcategoriaFiltrada}
              >
                Quitar Filtro
              </button>
            </div>
          </div>
          <div className="col-12 px-0 px-sm-4 px-md-5  d-block d-lg-none">
            <button
              onClick={this.abrirModal}
              className="btnAbrir py-1 "
            >
                           <span className="icon">
                        <svg viewBox="0 0 175 80" width="40" height="40">
                            <rect width="80" height="15" fill="#f0f0f0" rx="10"></rect>
                            <rect y="30" width="80" height="15" fill="#f0f0f0" rx="10"></rect>
                            <rect y="60" width="80" height="15" fill="#f0f0f0" rx="10"></rect>
                        </svg>
                    </span>
                    <span className="text">Filtros</span>
            </button>
            <Modal
              show={this.state.showModalFiltros}
              onHide={this.cerrarModal}
              centered
            >
              <Modal.Header className="text-center d-flex justify-content-center">
                <h2 className="tituloFiltros text-center">Filtros</h2>
              </Modal.Header>
              <Modal.Body>
                <h4 className="tituloFiltros mt-2">Colores</h4>
                <div hidden={this.state.hideColorFiltrado}>
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    key={"top"}
                    placement={"top"}
                    overlay={
                      <Popover id={`popover-positioned-top`}>
                        <Popover.Body>
                          {this.capitalizar(this.state.colorFiltro)}
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <button
                      className={
                        "btnColor mx-1 btnColor" +
                        this.btnColor(this.state.colorFiltro)
                      }
                      type="button"
                    />
                  </OverlayTrigger>
                </div>
                <div hidden={this.state.hideColores}>
                  {this.props.colores.map((e, index) => (
                    <OverlayTrigger
                      trigger={["hover", "focus"]}
                      key={index}
                      placement={"top"}
                      overlay={
                        <Popover id={`popover-positioned-top`}>
                          <Popover.Body>{this.capitalizar(e)}</Popover.Body>
                        </Popover>
                      }
                    >
                      <button
                        className={"btnColor mx-1 btnColor" + this.btnColor(e)}
                        value={e}
                        aria-label={"Boton de filtro color " + e}
                        onClick={this.filtrarColor}
                      ></button>
                    </OverlayTrigger>
                  ))}
                </div>
                <div className="text-end">
                  <button
                    onClick={this.quitarFiltroColores}
                    className="btnQuitarFiltros"
                    hidden={this.state.hideColorFiltrado}
                  >
                    Quitar Filtro
                  </button>
                </div>
                <hr />
                <h4 className="tituloFiltros mt-1">Tipo de Producto</h4>
                <div hidden={this.state.hideSubcategoriaFiltrada}>
                  <button type="button" className="mx-1 btnFiltros">
                    {this.capitalizar(this.state.subcategoriaFiltro)}
                  </button>
                </div>
                <div hidden={this.state.hideSubcategorias}>
                  {this.props.subcategorias.map((e, index) => (
                    <div key={index} style={{ width: "100%" }}>
                      <button
                        value={e}
                        className="mx-1 btnFiltros"
                        onClick={this.filtrarSubcategoria}
                      >
                        {this.capitalizar(e)}
                      </button>
                    </div>
                  ))}
                </div>
                <div className="text-end">
                  <button
                    onClick={this.quitarFiltroSubcategorias}
                    className="btnQuitarFiltros"
                    hidden={this.state.hideSubcategoriaFiltrada}
                  >
                    Quitar Filtro
                  </button>
                </div>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <button
                  className="btnQuitarFiltros w-25"
                  onClick={this.cerrarModal}
                >
                  Cerrar
                </button>
              </Modal.Footer>
            </Modal>
          </div>
          <div className="col-lg-9 pt-4 p-lg-0">
            <div className="row pt-0 px-0 px-sm-3">
              <div className="col-12 d-flex justify-content-center">
                <Pagination
                  count={this.state.noPaginas}
                  page={Number(
                    new URLSearchParams(window.location.search).get("page")
                  )}
                  onChange={this.handleChangePagination}
                  shape="rounded"
                  size="small"
                  color="error"
                />
              </div>
            </div>
            <div className="row py-0 px-0 px-sm-3">
              {this.props.data[0] ? (
                this.state.changingFiltros === false &&
                  (
                    this.props.data
                      .slice(
                        Number(
                          new URLSearchParams(window.location.search).get("page")
                        ) *
                          24 -
                          24,
                        Number(
                          new URLSearchParams(window.location.search).get("page")
                        ) * 24
                      )
                      .map((e, index) => <Tarjeta key={index} data={e} index={index}/>)
                  )
                  )
                : (
                <div className="col-12 text-center pt-5">
                  <h2>
                    No se encontraron productos, vuelva a ajustar los filtros
                    para encontrar coincidencias.
                  </h2>
                </div>
              )}
            </div>
            <div className="row py-0 px-0 px-sm-3 m-0">
              <div className="col-12 d-flex justify-content-center">
                <Pagination
                  count={this.state.noPaginas}
                  page={Number(
                    new URLSearchParams(window.location.search).get("page")
                  )}
                  onChange={this.handleChangePagination}
                  shape="rounded"
                  color="error"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Categoria;
