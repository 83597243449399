import React from 'react';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgsCarrusel: []
    }
  }

  componentDidMount = async () => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const response = await fetch('https://www.argpromocionales.com/php/imagenesCarousel.php', requestOptions);
    const data = await response.json();
    let imagenes = Object.values(data);
    imagenes = imagenes.sort((a,b)=>a.substring(0, a.indexOf('.'))-b.substring(0, b.indexOf('.')));
    this.setState({
      imgsCarrusel: imagenes
    })
  }
  
  render() { 
    return ( 
      <div id="carouselExampleIndicators" className="carousel slide mt-0" data-bs-ride="carousel">
        <div className="carousel-indicators">
          {
            this.state.imgsCarrusel.map((_,index) =>
              <button key={index} type='button' data-bs-target="#carouselExampleIndicators" data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current={index === 0} aria-label={"Slide " + (index + 1)}/>
            )
          }
        </div>
        <div className="carousel-inner">
          {
            this.state.imgsCarrusel.map((e,index) =>
              <div className={"carousel-item" + ((index === 0) ? ' active' : '') } key={index}>
                  <img src={"/img/carrusel/" + e} className="d-block w-100 h-50" alt=""/>
              </div>
            )
          }
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
     );
  }
}
 
export default Carousel;
