import React from 'react';
import Carousel from '../Components/Principal/Carousel';
import Medios from '../Components/Principal/Medios';
import Titulo from '../Components/Principal/Titulo';
import Catalogos from '../Components/Principal/Catalogos';
import { useEffect } from 'react';

const Inicio=()=> {

  useEffect(() => {
    document.title = 'ARG - Promocionales';
  }, []);

  return (
    <div> 
    <Titulo/>
    <Carousel/>
    <Medios/> 
    <Catalogos/>
    </div>
  )
}

export default Inicio