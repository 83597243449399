import React from 'react';
import '../../Components/Productos/producto.css';
import ImageGallery from 'react-image-gallery';
import { Popover } from 'react-bootstrap';
import { OverlayTrigger } from 'react-bootstrap';
import { SpinnerCircularFixed } from 'spinners-react';

class ProductoCO extends React.Component {
    constructor() {
        super();
        this.state = {
            fichaTecnica: [],
            existencias: [],
            descripcion: [],
            imgs: [],
            colores: [],
            imgGeneral: '',
            dataReady: false
        }
    }
 
    componentDidMount = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'getFichaCO',
                codigoGeneral: this.props.codigoGeneral
            })
        };
        const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
        const data = await response.json();
        let datos = data.datos;
        this.setState({
            fichaTecnica: datos[0],
            descripcion: datos[0].description.split('.')
        });
        document.title = 'ARG - ' + this.capitalizar(this.state.fichaTecnica.name.toLowerCase());
        this.cargarImgs();
        this.cargarColores();
        this.cargarExistencias();
    }
 
    cargarImgs = () => {
        let imgs = [];
        let imgGral = this.state.fichaTecnica.variants[0].picture.original;
        imgs[0] = {
            original: imgGral,
            originalHeight : '400px',
            thumbnail: imgGral,
            thumbnailTitle: 'Principal'
        };
        this.setState({
            imgs: imgs,
            imgGeneral: imgGral,
        })
    }

    changeImg = (img) => {
        if (img === this.state.imgs[0].original) {
            let imgs = this.state.imgs;
            imgs[0].original = this.state.imgGeneral;
            imgs[0].thumbnail = this.state.imgGeneral;
            this.setState({
                imgs: imgs
            })
        }else{
            let imgs = this.state.imgs;
            imgs[0].original = img;
            imgs[0].thumbnail = img;
            this.setState({
                imgs: imgs
            })
        }
    }

    cargarColores = async () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                operacion: 'getColores',
                codigoGeneral: this.props.codigoGeneral
            })
          };
        const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
        const data = await response.json();
        this.setState({
            colores: data.colores
        });
    }

    cargarExistencias = () => {
        let existencias = [];
        let datos = this.state.fichaTecnica.variants;
        datos.forEach((element, index) => {
            existencias[index] = {
                color: element.color,
                existencias: element.stock_available
            }
        });
        this.setState({
            existencias: existencias,
            dataReady: true
        })
    }

    capitalizar = (color) => {
        const arr = color.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(" ");
        return str2;
    }

    numberWithCommas = (x) => {
        x = Number(x).toFixed(2);
        if (x === '0.00') {
            return '0.00';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    numberWithCommas2 = (x) => {
        x = Number(x);
        if (x === 0) {
            return '0';
        }else{
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    }

    sentenceCase(input, lowercaseBefore) {
        input = ( input === undefined || input === null ) ? '' : input;
        if (lowercaseBefore) { input = input.toLowerCase(); }
        return input.toString().replace( /(^|\. *)([a-z])/g, function(match, separator, char) {
            return separator + char.toUpperCase();
        });
    }

    obtenerNombre = (texto) => {
        let nombre = '';
        if (texto) {
            nombre = texto.split(this.props.codigoGeneral)[0];
        }
        return nombre
    }
 
    render() {
        return (
            <>
                {
                    this.state.dataReady ?
                        <div className='container-fluid px-xl-5'>
                            <div className="row px-0">
                                <div className="col-12 text-center">
                                    <h1 style={{ textTransform: 'uppercase' }}>{this.state.fichaTecnica.name}</h1>
                                </div>
                            </div>
                            <div className='row px-0'>
                                <div className='col-md-4 col-xl-4 px-xl-5 d-none d-xl-block'>
                                    <div className="caja">Características</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline'>Código: </p>
                                                    <p className='m-0 p-0 d-inline' style={{ color: '#efefef' }}>CO - </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.codigoGeneral}</p>                                                   
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline'>Categoria: </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.subcategoria}</p>                                                   
                                                </td>
                                            </tr>
                                            {
                                                this.state.descripcion.map((e, index) => 
                                                    <tr key={index}>
                                                        <td style={{ textTransform: 'capitalize' }}>
                                                            <p className='m-0 p-0 d-inline'>{e.trim()[0] === '/' ? e.trim().replace('/', '') : e.trim()}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 col-xl-4 px-xl-4">
                                    <div className='row m-0 p-0'>
                                        {
                                            this.state.imgs !== '' &&
                                                <ImageGallery items={this.state.imgs} useBrowserFullscreen={false} showPlayButton={false} showNav={false} showFullscreenButton={false} onErrorImageURL={window.location.origin + '/img/noImage.jpg'}/>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-12 text-center">
                                            {this.state.colores.map((e, index) =>
                                                <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                key={index}
                                                placement={'top'}
                                                overlay={
                                                    <Popover id={`popover-positioned-top`}>
                                                    <Popover.Body>
                                                        {this.capitalizar(e.color)}
                                                    </Popover.Body>
                                                    </Popover>
                                                }
                                                >
                                                <button className={'btnColor mx-1 btnColor' + e.btnColor} onClick={ () => this.changeImg(e.img)}></button>
                                                </OverlayTrigger>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4 col-xl-4 px-xl-5 d-xl-none'>
                                    <div className="caja">Características</div>
                                    <table className="table table-responsive table-hover">
                                        <tbody>
                                            <tr>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline' >Código: </p>
                                                    <p className='m-0 p-0 d-inline' style={{ color: '#efefef' }}>CO - </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.codigoGeneral}</p>                                                   
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{ textTransform: 'capitalize' }}>
                                                    <p className='m-0 p-0 d-inline'>Categoria: </p>
                                                    <p className='m-0 p-0 d-inline'>{this.props.subcategoria}</p>                                                   
                                                </td>
                                            </tr>
                                            {
                                                this.state.descripcion.map((e, index) => 
                                                    <tr key={index}>
                                                        <td style={{ textTransform: 'capitalize' }}>
                                                            <p className='m-0 p-0 d-inline'>{e.trim()[0] === '/' ? e.trim().replace('/', '') : e.trim()}</p>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-4 col-xl-4 px-xl-5">
                                    <h3 className='text-center theadProducto'>Disponibilidad</h3>
                                    <table className='w-100 table table-hover'>
                                        <thead>
                                            <tr>
                                                <th style={{ width: '25%' }}>Color</th>
                                                <th className='text-center' style={{ width: '25%' }}>Existencia (Pzas.)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.existencias.map((e,index)=>
                                                    <tr key={index}>
                                                        <td>{this.capitalizar(e.color)}</td>
                                                        <td className='text-center'>{this.numberWithCommas2(e.existencias)}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    :
                        <div className='container-fluid px-xl-5'>
                            <div className="row p-5">
                                <div className="col-12 text-center p-5">
                                    <SpinnerCircularFixed size={130} thickness={70} speed={200} color="rgba(201, 13, 12, 1)" secondaryColor="rgba(164, 168, 177, 1)" />
                                </div>
                            </div>
                        </div>
                } 
            </>
        );
    }
}
 
export default ProductoCO;