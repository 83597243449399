import React from 'react';
import img from '../images/notFoundRoute.png'

class NotFoundRoute extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() { 
        return ( 
            <>
                <div className="container text-center">
                    <div className="row">
                        <div className="col">
                            <img src={img} alt="" width="50%"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <h2>LA PÁGINA QUE BUSCA NO EXISTE</h2>
                        </div>
                    </div>
                </div>
            </>
         );
    }
}
 
export default NotFoundRoute;