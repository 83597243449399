import '../css/barbus.css';
import React from 'react';
import debounce from 'lodash.debounce';
import ProductoLista from './ProductoLista';

class Barbus extends React.Component {
  constructor() {
    super();
    this.state = {
      parents: [],
      buscarInput : '',
      redirect: '',
      error: '',
      usuario: null,
      style: {
        width: '100px'
      },
      hideSinResultados: true 
    };
    this.onChangeDebounced = debounce(this.onChangeDebounced, 800);
    this.ref = React.createRef();
  }

  componentDidMount = async () => {
    document.addEventListener('click', this.handleClickOutside.bind(this), true);
    this.getWidthListaProductos();
    window.addEventListener("resize", this.getWidthListaProductos);
  }
  
  onChangeValue = (e) => {
    if (e.target.value === '') {
      this.setState({
        parents: [],
        buscarInput: ''
      })
    }else{
      this.setState({
        ...this.state,
        buscarInput: e.target.value
      });
      this.onChangeDebounced();
    }
  }

  onChangeDebounced = async () => {
    await this.buscar();
    if (this.state.parents.length === 0) {
      this.setState({
        hideSinResultados: false
      })
    }
  }

  buscar = async () => {
    if (this.state.buscarInput !== '') {
      const quitarAcentos = (str) => {return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");}
      let buscar = quitarAcentos(this.state.buscarInput).toLocaleLowerCase();
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
            operacion: 'filtrar',
            buscar: buscar,
        })
      };
      const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
      const data = await response.json();
      this.setState({
        parents: Object.values(data.parents)
      })
    }
  }

  buscarCompleto = async () => {
    const quitarAcentos = (str) => {return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");}
    let buscar = this.state.buscarInput;
    buscar = buscar.replace('%20', ' ');
    buscar = await quitarAcentos(buscar);
    window.location.href = "/busqueda?buscar=" + buscar + '&page=1';
  }

  enter = (e) => {
    this.setState({
      hideSinResultados: true
    })
    if (e.key === 'Enter') {
      this.buscarCompleto();
    }
  }

  getWidthListaProductos = () => {
    var elemento = document.getElementById('inputBuscar');
    let styleInput = window.getComputedStyle(elemento);
    let width = styleInput.getPropertyValue('width')
    let style = {
      width: width
    }
    this.setState({
      style: style
    })
  }

  handleClickOutside(event){
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({
        parents: [],
        buscarInput: ''
      })
    }
  }
  
  render() { 
    return ( 
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 w-100">
              <div className="row h-100 px-0">
                <div className="search w-100 col-md-12 col-lg-10 col-xxl-8 d-flex align-items-center justify-content-center px-0" >
                  <div className=' d-flex me-2'>
                    <input className="form-control inputBuscar" id='inputBuscar' type="search" placeholder="Encuentra lo que buscas" onChange={this.onChangeValue} value={this.state.buscarInput} onKeyPress={this.enter}/>
                      <ul className="list-group" style={this.state.style} ref={this.ref}>
                        {this.state.parents.slice(0, 10).map((producto,index) =>
                            <ProductoLista key={index} data={producto}/>
                          )}
                          {(this.state.buscarInput !== '' && this.state.parents.length === 0 ) &&
                            <li className='list-group-item sinResultados' hidden={this.state.hideSinResultados}>Sin Resultados</li>
                          }
                      </ul>
                  </div>
                  <button className="btn btn-danger" type="button" aria-label='Boton buscar' onClick={this.buscarCompleto}><i className="bi bi-search"></i></button>
                </div>
              </div>
          </div>
        </div>
      </div>
     );
  }
}
 
export default Barbus;

