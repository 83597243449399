
import React, {useRef} from 'react';
import emailjs from '@emailjs/browser';
import { useEffect } from 'react';
import './contacto.css';
import logoContacto from '../images/contacto.png'

export const Contacto = () =>{

  useEffect(() => {
    document.title = 'ARG - Contacto';
  }, []);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      "service_ah93b7a",
      "template_49fevcp",
      form.current,
      "rHU47NfVz99SmU06o"
      ).then((result) => {
        console.log(result.text);
        }, (error) => {
        console.log(error.text);
        });
        alert('Enviado, gracias por comunicarte.');
        window.location.reload();
  };

return (
  <>
  <div className="container">
    <h2 className='text-center mt-4'>Mándanos un correo y nos pondremos en contacto contigo</h2>
    <hr />
  </div>
  <div className='container contacto'>
    <form ref={form} onSubmit={sendEmail}>
      <div className="row">
        <div className="col-lg-9">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <label htmlFor='' className='col-lg-2 col-form-label'>Nombre:</label>
                <div className="col-lg-10">
                  <input type="text" name="user_name" className="form-control" ></input>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <label htmlFor='' className='col-lg-2 col-form-label'>Correo:</label>
                <div className="col-lg-10">
                  <input type="email" name="user_email" className="form-control"></input>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <label htmlFor='' className='col-lg-2 col-form-label'>Teléfono:</label>
                <div className="col-lg-10">
                  <input type="tel" name="user_number"className="form-control"></input>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <label htmlFor='' className='col-lg-2 col-form-label'>Estado:</label>
                <div className="col-lg-10">
                  <input type="text" name="user_estado" className="form-control"></input>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <label htmlFor='' className='col-lg-1 col-form-label'>Motivo:</label>
                <div className="col-lg-11">
                  <textarea name="message" className="form-control" rows="3"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3 d-lg-flex justify-content-center align-items-center d-none">
          <img src={logoContacto} alt="" width={'60%'}/>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center">
                <input type="submit" className="btn btn-danger btnEnviar"value="Enviar" />
              </div>
        </div>
    </form>
  </div>
  </>
);
};

export default Contacto