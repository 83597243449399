import './css/App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Routes from './Components/Routes';
import Menu from './Components/Menu';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './Components/Principal/Footer';
import WhatsappBtn from './Components/WhatsappBtn';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-233550602-1";
ReactGA.initialize(TRACKING_ID);
function App() {

  return (
      <Router>
          <Menu/>
          <Routes/>
          <WhatsappBtn/>
          <Footer/>
      </Router>
        
  );
}
export default App;
