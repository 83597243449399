import React from 'react';
import Categoria from './Categorias/Categoria';
import { SpinnerInfinity } from 'spinners-react';

class Busqueda extends React.Component {
    constructor(props) {
        super(props);
        this.params = new URLSearchParams(window.location.search);
        this.state = {
            data: [],
            colores: [],
            categoria: '',
            subcategorias: [],
            parents: [],
            dataReady: false
        }
    }

    componentDidMount = async () => {
        document.title = `ARG - Búsqueda "` + this.params.get('buscar')[0].toUpperCase() + this.params.get('buscar').substring(1) + `"`;
        let color = '';
        if (this.params.get("color")) {
            color = this.params.get("color");
          }
          let subcategoria = '';
        if (this.params.get("cat")) {
            subcategoria = this.params.get("cat");
        }
        let buscar = '';
        if (this.params.get("buscar")) {
            buscar = this.params.get("buscar");
            if (buscar !== '') {
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ 
                        operacion: 'filtrar',
                        subcategoria: subcategoria,
                        color: color,
                        buscar: buscar,
                    })
                  };
                const response = await fetch('https://www.argpromocionales.com/php/consultas.php', requestOptions);
                const data = await response.json();
                this.setState({
                    data: data.datos,
                    colores: data.colores,
                    subcategorias: data.subcategorias,
                    parents: Object.values(data.parents),
                    dataReady: true
                });
            }else{
                window.location.href = "/";
            }
        }else{
            window.location.href = "/";
        }
    }

    render() {

        return ( 
            <>
                <div className="container-fluid">
                    {
                        this.state.dataReady ?
                                <Categoria
                                data={this.state.parents}
                                colores={this.state.colores}
                                subcategorias={this.state.subcategorias}
                                />
                        :
                        <div className="row">
                            <div className="col-12 d-flex justify-content-center mt-5 mb-5">
                                <div style={{ backgroundColor: '#53565C', width: 'fit-content', padding: '20px', borderRadius: '10px' }}>
                                    <SpinnerInfinity size={200} thickness={70} speed={150} color="rgba(206, 6, 5, 1)" secondaryColor="rgba(255, 255, 255, 1)"/>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
         );
    }
}
 
export default Busqueda;