import React from 'react'
import '../../css/medios.css'

class Medios extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imgs: [],
      catalogos: []
    }
  }

  componentDidMount = async() => {
      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const response = await fetch('https://www.argpromocionales.com/php/imagenesTarjetas.php', requestOptions);
    const data = await response.json();
    const requestOptionsCatalogo = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };
    const responseCatalogo = await fetch('https://www.argpromocionales.com/php/catalogosInicio.php', requestOptionsCatalogo);
    const dataCatalogo = await responseCatalogo.json();
    let imagenes = Object.values(data);
    this.setState({
      imgs: imagenes,
      catalogos: dataCatalogo
    })
  }
  
  render() { 
    return ( 
        <div className="container-fluid medios">
          <div className="row text-center">
            <div className="col-md-4">
              {
                this.state.catalogos.cat1 ?
                  <a href="/pdf/Catalogo-1.pdf" target='_blank'>
                    <div className="card card-flip" style={{ cursor: 'pointer' }}>
                      <div className="card-front">
                        <img src={'/img/tarjetasInicio/' + this.state.imgs[0]} width="100%" height="100%" alt=''></img>
                      </div>
                      <div className="card-back p-0">
                          <img src={'/img/tarjetasInicio/' + this.state.imgs[1]} width="100%" height="100%" alt=''></img>
                      </div>
                    </div>
                  </a>
                  :
                  <div className="card card-flip">
                    <div className="card-front">
                      <img src={'/img/tarjetasInicio/' + this.state.imgs[0]} width="100%" height="100%" alt=''></img>
                    </div>
                    <div className="card-back p-0">
                        <img src={'/img/tarjetasInicio/' + this.state.imgs[1]} width="100%" height="100%" alt=''></img>
                    </div>
                  </div>
              }
            </div>
            <div className="col-md-4">
              {
                this.state.catalogos.cat2 ?
                  <a href="/pdf/Catalogo-2.pdf" target='_blank'>
                    <div className="card card-flip" style={{ cursor: 'pointer' }}>
                      <div className="card-front">
                        <img src={'/img/tarjetasInicio/' + this.state.imgs[2]} width="100%" height="100%" alt=''></img>
                      </div>
                      <div className="card-back p-0">
                        <img src={'/img/tarjetasInicio/' + this.state.imgs[3]} width="100%" height="100%" alt=''></img>
                      </div>
                    </div>
                  </a>
                  :
                  <div className="card card-flip">
                    <div className="card-front">
                      <img src={'/img/tarjetasInicio/' + this.state.imgs[2]} width="100%" height="100%" alt=''></img>
                    </div>
                    <div className="card-back p-0">
                      <img src={'/img/tarjetasInicio/' + this.state.imgs[3]} width="100%" height="100%" alt=''></img>
                    </div>
                  </div>
              }
            </div>
            <div className="col-md-4">
              {
                this.state.catalogos.cat3 ?
                  <a href="/pdf/Catalogo-3.pdf" target='_blank'>
                    <div className="card card-flip" style={{ cursor: 'pointer' }}>
                      <div className="card-front">
                        <img src={'/img/tarjetasInicio/' + this.state.imgs[4]} width="100%" height="100%" alt=''></img>
                      </div>
                      <div className="card-back p-0">
                        <img src={'/img/tarjetasInicio/' + this.state.imgs[5]} width="100%" height="100%" alt=''></img>
                      </div>
                    </div>
                  </a>
                  :
                  <div className="card card-flip">
                    <div className="card-front">
                      <img src={'/img/tarjetasInicio/' + this.state.imgs[4]} width="100%" height="100%" alt=''></img>
                    </div>
                    <div className="card-back p-0">
                      <img src={'/img/tarjetasInicio/' + this.state.imgs[5]} width="100%" height="100%" alt=''></img>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
     );
  }
}
 
export default Medios;